
.forPageMain {
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
  animation-name: anim;
  animation-timing-function: ease-out;
}

@keyframes anim {
  from {
    background-color: rgba(19, 20, 21, 0);
  }
  to {
    background-color: rgba(19, 20, 21, 1);
  }
}

.page {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: 'Yanone Kaffeesatz', sans-serif;
  letter-spacing: 6px;

  margin-top: -45px;
}

.itemIndent {
  margin-top: 40px;
}

.photos {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  animation-duration: 2000ms;
  animation-fill-mode: forwards;
  animation-name: anim-footer;
  animation-timing-function: ease-out;
  animation-delay: 200ms;
  opacity: 0;
}

@keyframes anim-footer {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
