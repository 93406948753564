.hamMenuItem {
  color: white;
  font-size: var(--font-size-ham-menu-item);
  font-weight: 500;
  letter-spacing: 15px;
}

.hamMenuItem:hover {
  text-decoration: underline;
}


.navMenuItem {
  color: white;
  font-size: var(--font-size-nav-menu-item);
  letter-spacing: 8px;
}

.navMenuItem:hover {
  text-decoration: underline;
}

.modHeaderTitleMob {
  font-size: var(--font-size-mod-header-title);
  color: white;
  letter-spacing: 22px;
  line-height: 1.3em;
  font-weight: 300;
  font-family: 'Fjalla One', sans-serif;
}

.modTitle, .modTitleVideo {
  color: white;
  text-align: center;
  font-size: var(--font-size-mod-title);
  font-weight: 500;
  letter-spacing: 3px;
}

.modTitleVideo {
  letter-spacing: 12px;
}

.modTitleBioLastName, .modTitleBioFirstName {
  color: white;
  font-weight: 400;
  letter-spacing: 25px;
}

.modTitleBioLastName {
  font-size: calc(var(--font-size-mod-title) * 1.5);
}

.modTitleBioFirstName {
  font-size: calc(var(--font-size-mod-title) * 1.1);
}

.modContactInput {
  color: white;
  font-size: 26px;
  letter-spacing: 3px;
}

.footerCopyright {
  color: #979797;
  font-size: 14px;
}


.textBio {
  font-size: 24px;
  color: #fff;
  text-align: justify;
  margin-left: var(--indent-s);
  margin-right: var(--indent-s);
}

.textBio p {
  text-indent: 1em
}

.textBio p:first-child {
  margin-top: 0;
}


.btnOrange {
  color: white;
  font-size: 22px;
  letter-spacing: 3px;
}

.btnDark {
  color: rgba(0, 0, 0, 0.9);
  font-size: 22px;
  letter-spacing: 3px;
}

/* sm */
@media (min-width: 576px) {}



/* md */
@media (min-width: 768px) {
  .navMenuItem {
    letter-spacing: 2px;
  }

  .modHeaderTitleMob {
    color: #DA0000;
    letter-spacing: 30px;
  }

  .textBio {
    font-size: 24px;
    text-align: justify;
    margin-left: var(--indent-l);
    margin-right: var(--indent-l);
  }
}



/* lg */
@media (min-width: 992px) {}



/* xl */
@media (min-width: 1200px) {
  .modHeaderTitleMob {
    letter-spacing: 40px;
  }
}

